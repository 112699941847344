@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v1.6.1/mapbox-gl.css');

@layer base {

  html, body{
      background: white;
      color: #2D3D51;
      font-family: 'Poppins', sans-serif;
  }

  body{
    @apply flex flex-col min-h-screen;
  }

  * {
    box-sizing: border-box;
  }


  .on-sale-marker {
    display: block;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .sold-marker{
    display: block;
    border: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat; 
  }

}